import { GoogleButton } from 'features/authentication/googleButton';
import { TermsAndPrivacyLinks } from 'features/authentication/termsAndPrivacyLinks';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Typography } from 'features/ui/typography/typography';
import { useRef } from 'react';
import { WhiteCloud } from 'shared/images/whiteCloud';
import { scrollToElement } from 'shared/utils/commonUtils';
import checkIcon from '../../shared/icons/check.svg';
import unlockBigSpiralImage from '../../shared/images/unlock_big_spiral.png';
import unlockSpiralImage from '../../shared/images/unlock_spiral.png';
import unlockImg from './unlock.png';

export const Unlock = () => {
  const lastElement = useRef<HTMLDivElement>(null);

  return (
    <RouteLayout
      backRoute={-1}
      bottomElement={
        <div className="d-flex flex-column align-items-center gap-3 py-4 mx-4" style={{ position: 'relative', overflow: 'hidden' }}>
          <img src={unlockSpiralImage} alt="" style={{ position: 'absolute', zIndex: 0, top: 0 }} height={'100%'} />
          <div style={{ zIndex: 1 }}>
            <GoogleButton label="Zaloguj z Google" />
            <div className=" mt-1">
              <TermsAndPrivacyLinks />
            </div>
          </div>
        </div>
      }
    >
      <div style={{ position: 'static' }}>
        <div style={{ position: 'relative' }}>
          <img
            src={unlockImg}
            alt=""
            width="100%"
            onLoad={() => {
              lastElement.current && scrollToElement(lastElement.current);
            }}
          />
          <WhiteCloud />
        </div>
      </div>
      <div className="mx-3" style={{ position: 'static', overflow: 'hidden' }} ref={lastElement}>
        <div style={{ position: 'relative' }}>
          <img src={unlockBigSpiralImage} alt="" style={{ position: 'absolute', zIndex: 0, top: 0, right: 0 }} />
        </div>

        <Typography variant="h1" classNames="pb-2">
          Odkryj magiczny świat bajek ze Storytailo!
        </Typography>

        <div className="py-2 ">
          <div className="py-2 d-flex align-items-start gap-3">
            <img src={checkIcon} alt="" className="d-flex" />
            <div className="d-flex flex-column">
              <Typography variant="h4" classNames="me-2">
                Zostańcie bohaterami
              </Typography>
              <Typography variant="description">
                Pozwól aby Twoje dzieci stały się bohaterami bajek razem z Tobą i innymi postaciami.
              </Typography>
            </div>
          </div>
          <div className="py-2 d-flex align-items-start gap-3">
            <img src={checkIcon} alt="" className="d-flex" />
            <div className="d-flex flex-column">
              <Typography variant="h4" classNames="me-2">
                Bawcie się i rozwijajcie
              </Typography>
              <Typography variant="description">
                Nasze bajki są starannie opracowane, aby nawić i uczyć Twoje dzieci nowych umiejętności i wiedzy w przystępny sposób.
              </Typography>
            </div>
          </div>
          <div className="py-2 d-flex align-items-start gap-3">
            <img src={checkIcon} alt="" className="d-flex" />
            <div className="d-flex flex-column">
              <Typography variant="h4" classNames="me-2">
                Słuchajcie lub czytajcie
              </Typography>
              <Typography variant="description">
                Każdą bajkę możesz przeczytać sam lub wysłuchać wraz z delikatnym podkładem muzycznym, co dodaje magii do każdej historii.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </RouteLayout>
  );
};
